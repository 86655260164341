import dayjs from 'dayjs';
import { IEnterprise } from 'app/shared/model/enterprise.model';
import { IForm } from 'app/shared/model/form.model';
import { FormState } from 'app/shared/model/enumerations/form-state.model';

export interface IFormData {
  id?: number;
  group?: string;
  type?: string;
  data?: string | null;
  reason?: string | null;
  state?: FormState | null;
  meta?: string | null;
  belongsTo?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  auditor?: IEnterprise | null;
  form?: IForm | null;
}

export const defaultValue: Readonly<IFormData> = {};
