import dayjs from 'dayjs';
import { ReportType } from 'app/shared/model/enumerations/report-type.model';
import { ReportState } from 'app/shared/model/enumerations/report-state.model';

export interface IReport {
  id?: number;
  userId?: string | null;
  reportType?: ReportType | null;
  reportedUser?: string | null;
  reportedEntity?: string;
  content?: string | null;
  state?: ReportState | null;
  reason?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
}

export const defaultValue: Readonly<IReport> = {};
