import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IRoom } from 'app/shared/model/room.model';

export interface IRoomSpeaker {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  websiteUrl?: string | null;
  avatarUrl?: string | null;
  createdAt?: string | null;
  user?: IUser | null;
  room?: IRoom | null;
}

export const defaultValue: Readonly<IRoomSpeaker> = {};
