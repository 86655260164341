import dayjs from 'dayjs';
import { ICohortPostComment } from 'app/shared/model/cohort-post-comment.model';
import { IUser } from 'app/shared/model/user.model';
import { ICohort } from 'app/shared/model/cohort.model';
import { ICohortProject } from 'app/shared/model/cohort-project.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface ICohortPost {
  id?: number;
  title?: string;
  type?: string;
  content?: string | null;
  meta?: string | null;
  state?: EntityState | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  numLikes?: number | null;
  numViews?: number | null;
  pinned?: boolean | null;
  comments?: ICohortPostComment[] | null;
  user?: IUser | null;
  cohort?: ICohort | null;
  project?: ICohortProject | null;
}

export const defaultValue: Readonly<ICohortPost> = {
  pinned: false,
};
