import dayjs from 'dayjs';
import { IRoomChat } from 'app/shared/model/room-chat.model';
import { IRoomSpeaker } from 'app/shared/model/room-speaker.model';
import { IRoomView } from 'app/shared/model/room-view.model';
import { IRoomInterest } from 'app/shared/model/room-interest.model';
import { IRoomLiked } from 'app/shared/model/room-liked.model';
import { IRoomReaction } from 'app/shared/model/room-reaction.model';
import { IRoomRating } from 'app/shared/model/room-rating.model';
import { IRoomNote } from 'app/shared/model/room-note.model';
import { IUser } from 'app/shared/model/user.model';
import { IRoomTag } from 'app/shared/model/room-tag.model';
import { IRole } from 'app/shared/model/role.model';
import { IEnterprise } from 'app/shared/model/enterprise.model';
import { ICohort } from 'app/shared/model/cohort.model';
import { ICohortProject } from 'app/shared/model/cohort-project.model';
import { RoomFlag } from 'app/shared/model/enumerations/room-flag.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface IRoom {
  id?: number;
  title?: string | null;
  description?: string | null;
  thumbnailUrl?: string | null;
  scheduledTime?: string | null;
  startedTime?: string | null;
  endTime?: string | null;
  meta?: string | null;
  timezone?: string | null;
  recordResourceId?: string | null;
  flag?: RoomFlag | null;
  ratingString?: string | null;
  features?: string | null;
  state?: EntityState | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  chats?: IRoomChat[] | null;
  speakers?: IRoomSpeaker[] | null;
  views?: IRoomView[] | null;
  interests?: IRoomInterest[] | null;
  likes?: IRoomLiked[] | null;
  reactions?: IRoomReaction[] | null;
  ratings?: IRoomRating[] | null;
  notes?: IRoomNote[] | null;
  users?: IUser[] | null;
  tags?: IRoomTag[] | null;
  roles?: IRole[] | null;
  authorizeds?: IUser[] | null;
  enterprises?: IEnterprise[] | null;
  cohort?: ICohort | null;
  project?: ICohortProject | null;
}

export const defaultValue: Readonly<IRoom> = {};
