import React, { useState } from 'react';
import { LockOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, message, Tabs } from 'antd';
import { ProFormCaptcha, ProFormCheckbox, ProFormText, LoginForm } from '@ant-design/pro-form';
import Footer from '@/shared/layout/footer';

import styles from './index.less';
import './style.less';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { login } from 'app/shared/reducers/authentication';
import { LocaleMenu } from '@/shared/layout/menus';
import { Storage } from 'react-jhipster';
import { setLocale } from '@/shared/reducers/locale';
import { translate, Translate } from 'react-jhipster';

const LoginMessage: React.FC<{
  content: string;
}> = ({ content }) => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type="error"
    showIcon
  />
);

const Login = (props: RouteComponentProps<any>) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const [type, setType] = useState<string>('account');

  const handleLogin = (username, password, rememberMe = false) => dispatch(login(username, password, rememberMe));

  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const { location } = props;
  const { from } = (location.state as any) || { from: { pathname: '/admin/home', search: location.search } };
  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const handleLocaleChange = value => {
    Storage.session.set('locale', value);
    dispatch(setLocale(value));
  };

  const handleSendCode = async phone => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('resolved');
      }, 2000);
    });
  };

  const handleSubmit: (value: any) => Promise<boolean> = async values => {
    return new Promise(resolve => {
      handleLogin(values.username, values.password, values.autoLogin);
      // waiting
      setTimeout(() => {
        resolve(true);
      }, 2000);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoginForm
          logo={<img alt="logo" src="content/images/logo.svg" />}
          title=""
          subTitle={translate('login.promote')}
          initialValues={{
            autoLogin: true,
          }}
          submitter={{
            searchConfig: {
              submitText: translate('login.form.button'),
            },
            render: function render(_, dom) {
              return dom.pop();
            },
            submitButtonProps: {
              size: 'large',
              style: {
                width: '100%',
              },
            },
          }}
          onFinish={handleSubmit}
        >
          <Tabs
            activeKey={type}
            onChange={setType}
            tabBarExtraContent={<LocaleMenu currentLocale={currentLocale} onChange={handleLocaleChange} />}
          >
            <Tabs.TabPane key="account" tab={translate('login.method.password')} />
            <Tabs.TabPane key="mobile" tab={translate('login.method.phone')} />
          </Tabs>

          {loginError && type === 'account' && <LoginMessage content={translate('login.messages.error.authentication')} />}
          {type === 'account' && (
            <>
              <ProFormText
                name="username"
                fieldProps={{
                  size: 'large',
                  prefix: <UserOutlined className={styles.prefixIcon} />,
                }}
                placeholder={translate('global.form.username.placeholder')}
                rules={[
                  {
                    required: true,
                    message: translate('entity.validation.required'),
                  },
                ]}
              />
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: 'large',
                  prefix: <LockOutlined className={styles.prefixIcon} />,
                }}
                placeholder={translate('login.form.password.placeholder')}
                rules={[
                  {
                    required: true,
                    message: translate('entity.validation.required'),
                  },
                ]}
              />
            </>
          )}

          {loginError && type === 'mobile' && <LoginMessage content="验证码错误" />}
          {type === 'mobile' && (
            <>
              <ProFormText
                fieldProps={{
                  size: 'large',
                  prefix: <MobileOutlined className={styles.prefixIcon} />,
                }}
                name="mobile"
                placeholder={translate('login.form.mobile')}
                rules={[
                  {
                    required: true,
                    message: translate('entity.validation.required'),
                  },
                  {
                    pattern: /^1\d{10}$/,
                    message: translate('entity.validation.number'),
                  },
                ]}
              />
              <ProFormCaptcha
                fieldProps={{
                  size: 'large',
                  prefix: <LockOutlined className={styles.prefixIcon} />,
                }}
                captchaProps={{
                  size: 'large',
                }}
                placeholder={translate('login.form.captcha')}
                captchaTextRender={(timing, count) => {
                  return translate('login.form.getcaptcha');
                  // if (timing) {
                  //   return `${count} ${intl.formatMessage({
                  //     id: 'pages.getCaptchaSecondText',
                  //     defaultMessage: '获取验证码',
                  //   })}`;
                  // }
                  // return intl.formatMessage({
                  //   id: 'pages.login.phoneLogin.getVerificationCode',
                  //   defaultMessage: '获取验证码',
                  // });
                }}
                name="captcha"
                rules={[
                  {
                    required: true,
                    message: translate('entity.validation.required'),
                  },
                ]}
                onGetCaptcha={async phone => {
                  await handleSendCode(phone);
                  // TODO: update mobile
                  const result = false;
                  if (result === false) {
                    return;
                  }
                  message.success('获取验证码成功！验证码为：1234');
                }}
              />
            </>
          )}
          <div
            style={{
              marginBottom: 24,
            }}
          >
            <ProFormCheckbox noStyle name="autoLogin">
              <Translate contentKey="login.form.rememberme">自动登录</Translate>
            </ProFormCheckbox>
            <a
              style={{
                float: 'right',
              }}
            >
              <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
            </a>
          </div>
        </LoginForm>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
