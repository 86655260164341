import { IUser } from 'app/shared/model/user.model';

export interface IUserInterest {
  id?: number;
  name?: string;
  entityType?: string;
  entityId?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserInterest> = {};
