import { IChatPoll } from 'app/shared/model/chat-poll.model';

export interface IChatPollOption {
  id?: number;
  title?: string | null;
  count?: number | null;
  poll?: IChatPoll | null;
}

export const defaultValue: Readonly<IChatPollOption> = {};
