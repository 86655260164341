import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserGroup {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  users?: IUser[] | null;
}

export const defaultValue: Readonly<IUserGroup> = {};
