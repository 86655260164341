import dayjs from 'dayjs';
import { IRoom } from 'app/shared/model/room.model';

export interface IRoomNote {
  id?: number;
  content?: string | null;
  category?: string | null;
  timeStamp?: string | null;
  timeFromStart?: number | null;
  replyCount?: number | null;
  userId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  room?: IRoom | null;
}

export const defaultValue: Readonly<IRoomNote> = {};
