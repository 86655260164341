import dayjs from 'dayjs';
import { IChatPoll } from 'app/shared/model/chat-poll.model';

export interface IChatPollResponse {
  id?: number;
  userId?: string | null;
  option?: string | null;
  createdAt?: string | null;
  poll?: IChatPoll | null;
}

export const defaultValue: Readonly<IChatPollResponse> = {};
