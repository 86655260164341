import { IRoom } from 'app/shared/model/room.model';

export interface IRoomTag {
  id?: number;
  title?: string | null;
  counts?: number | null;
  rooms?: IRoom[] | null;
}

export const defaultValue: Readonly<IRoomTag> = {};
