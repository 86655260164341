import { IUser } from 'app/shared/model/user.model';

export interface IWork {
  id?: number;
  name?: string;
  type?: string;
  users?: IUser[] | null;
}

export const defaultValue: Readonly<IWork> = {};
