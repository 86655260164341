import dayjs from 'dayjs';

export interface ISearchHistory {
  id?: number;
  content?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
}

export const defaultValue: Readonly<ISearchHistory> = {};
