import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserSettings {
  id?: number;
  browserEnable?: boolean | null;
  emailEnable?: boolean | null;
  notification?: string | null;
  meta?: string | null;
  updatedAt?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserSettings> = {
  browserEnable: false,
  emailEnable: false,
};
