import dayjs from 'dayjs';
import { IRoom } from 'app/shared/model/room.model';

export interface IRoomLiked {
  id?: number;
  userId?: string | null;
  createdAt?: string | null;
  room?: IRoom | null;
}

export const defaultValue: Readonly<IRoomLiked> = {};
