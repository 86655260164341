import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface INotification {
  id?: number;
  title?: string | null;
  content?: string | null;
  link?: string | null;
  type?: string | null;
  subType?: string | null;
  meta?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  platform?: string | null;
  audience?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<INotification> = {};
