import dayjs from 'dayjs';
import { ICohortPoll } from 'app/shared/model/cohort-poll.model';

export interface ICohortPollResponse {
  id?: number;
  userId?: string;
  option?: string;
  createdAt?: string | null;
  poll?: ICohortPoll | null;
}

export const defaultValue: Readonly<ICohortPollResponse> = {};
