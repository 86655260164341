import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ICohortProject } from 'app/shared/model/cohort-project.model';
import { ICohortDeliverable } from 'app/shared/model/cohort-deliverable.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface ICohortTeam {
  id?: number;
  meta?: string | null;
  name?: string;
  bio?: string | null;
  state?: EntityState | null;
  capacity?: number | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  users?: IUser[] | null;
  project?: ICohortProject | null;
  deliverables?: ICohortDeliverable[] | null;
}

export const defaultValue: Readonly<ICohortTeam> = {};
