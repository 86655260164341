import dayjs from 'dayjs';
import { IRoom } from 'app/shared/model/room.model';

export interface IRoomRating {
  id?: number;
  rating1?: number | null;
  rating2?: number | null;
  rating3?: number | null;
  rating4?: number | null;
  rating5?: number | null;
  comment?: string | null;
  userId?: string | null;
  createdAt?: string | null;
  room?: IRoom | null;
}

export const defaultValue: Readonly<IRoomRating> = {};
