import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    data-cy="entity"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <>{/* to avoid warnings when empty */}</>
    <MenuItem icon="asterisk" to="/cohort">
      <Translate contentKey="global.menu.entities.cohort" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/role">
      <Translate contentKey="global.menu.entities.role" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/permission">
      <Translate contentKey="global.menu.entities.permission" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-group">
      <Translate contentKey="global.menu.entities.userGroup" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tag">
      <Translate contentKey="global.menu.entities.tag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/file">
      <Translate contentKey="global.menu.entities.file" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/file-group">
      <Translate contentKey="global.menu.entities.fileGroup" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/search-history">
      <Translate contentKey="global.menu.entities.searchHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/access-history">
      <Translate contentKey="global.menu.entities.accessHistory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-settings">
      <Translate contentKey="global.menu.entities.userSettings" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-interest">
      <Translate contentKey="global.menu.entities.userInterest" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/school">
      <Translate contentKey="global.menu.entities.school" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/work">
      <Translate contentKey="global.menu.entities.work" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-tag">
      <Translate contentKey="global.menu.entities.userTag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/enterprise">
      <Translate contentKey="global.menu.entities.enterprise" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/service-subscription">
      <Translate contentKey="global.menu.entities.serviceSubscription" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/report">
      <Translate contentKey="global.menu.entities.report" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-project">
      <Translate contentKey="global.menu.entities.cohortProject" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-team">
      <Translate contentKey="global.menu.entities.cohortTeam" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-category">
      <Translate contentKey="global.menu.entities.cohortCategory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-post">
      <Translate contentKey="global.menu.entities.cohortPost" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-post-comment">
      <Translate contentKey="global.menu.entities.cohortPostComment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-poll">
      <Translate contentKey="global.menu.entities.cohortPoll" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-poll-option">
      <Translate contentKey="global.menu.entities.cohortPollOption" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-poll-response">
      <Translate contentKey="global.menu.entities.cohortPollResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-deliverable">
      <Translate contentKey="global.menu.entities.cohortDeliverable" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/file-attachment">
      <Translate contentKey="global.menu.entities.fileAttachment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room">
      <Translate contentKey="global.menu.entities.room" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-tag">
      <Translate contentKey="global.menu.entities.roomTag" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-speaker">
      <Translate contentKey="global.menu.entities.roomSpeaker" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-reaction">
      <Translate contentKey="global.menu.entities.roomReaction" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-rating">
      <Translate contentKey="global.menu.entities.roomRating" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-liked">
      <Translate contentKey="global.menu.entities.roomLiked" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-view">
      <Translate contentKey="global.menu.entities.roomView" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-interest">
      <Translate contentKey="global.menu.entities.roomInterest" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-note">
      <Translate contentKey="global.menu.entities.roomNote" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-feedback">
      <Translate contentKey="global.menu.entities.roomFeedback" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/room-chat">
      <Translate contentKey="global.menu.entities.roomChat" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/chat-content">
      <Translate contentKey="global.menu.entities.chatContent" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/chat-poll">
      <Translate contentKey="global.menu.entities.chatPoll" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/chat-poll-option">
      <Translate contentKey="global.menu.entities.chatPollOption" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/chat-poll-response">
      <Translate contentKey="global.menu.entities.chatPollResponse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/notification">
      <Translate contentKey="global.menu.entities.notification" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/invoice">
      <Translate contentKey="global.menu.entities.invoice" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/form">
      <Translate contentKey="global.menu.entities.form" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/form-data">
      <Translate contentKey="global.menu.entities.formData" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/form-field-group">
      <Translate contentKey="global.menu.entities.formFieldGroup" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/form-field">
      <Translate contentKey="global.menu.entities.formField" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cohort-deliverable-submission">
      <Translate contentKey="global.menu.entities.cohortDeliverableSubmission" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
