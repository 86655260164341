import { IChatPoll } from 'app/shared/model/chat-poll.model';
import { IChatContent } from 'app/shared/model/chat-content.model';
import { IRoom } from 'app/shared/model/room.model';
import { ChatStatus } from 'app/shared/model/enumerations/chat-status.model';

export interface IRoomChat {
  id?: number;
  chatId?: string | null;
  state?: ChatStatus | null;
  polls?: IChatPoll[] | null;
  contents?: IChatContent[] | null;
  room?: IRoom | null;
}

export const defaultValue: Readonly<IRoomChat> = {};
