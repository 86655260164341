import dayjs from 'dayjs';
import { IFormData } from 'app/shared/model/form-data.model';
import { IRoom } from 'app/shared/model/room.model';

export interface IEnterprise {
  id?: number;
  title?: string;
  type?: string;
  identifier?: string | null;
  logoUrl?: string | null;
  coverUrl?: string | null;
  websiteUrl?: string | null;
  desc?: string | null;
  userId?: string | null;
  validated?: boolean | null;
  validatedUntil?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  froms?: IFormData[] | null;
  rooms?: IRoom[] | null;
}

export const defaultValue: Readonly<IEnterprise> = {
  validated: false,
};
