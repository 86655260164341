import dayjs from 'dayjs';
import { IFormData } from 'app/shared/model/form-data.model';
import { IFormFieldGroup } from 'app/shared/model/form-field-group.model';

export interface IForm {
  id?: number;
  title?: string;
  type?: string;
  meta?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  data?: IFormData[] | null;
  groups?: IFormFieldGroup[] | null;
}

export const defaultValue: Readonly<IForm> = {};
