import dayjs from 'dayjs';
import { ICohortPost } from 'app/shared/model/cohort-post.model';
import { ICohortPoll } from 'app/shared/model/cohort-poll.model';
import { ICohortTeam } from 'app/shared/model/cohort-team.model';
import { ICohortDeliverable } from 'app/shared/model/cohort-deliverable.model';
import { IRoom } from 'app/shared/model/room.model';
import { ICohort } from 'app/shared/model/cohort.model';
import { CohortProjectState } from 'app/shared/model/enumerations/cohort-project-state.model';

export interface ICohortProject {
  id?: number;
  name?: string;
  description?: string;
  state?: CohortProjectState | null;
  startDate?: string | null;
  endDate?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  posts?: ICohortPost[] | null;
  polls?: ICohortPoll[] | null;
  teams?: ICohortTeam[] | null;
  deliverables?: ICohortDeliverable[] | null;
  rooms?: IRoom[] | null;
  cohort?: ICohort | null;
}

export const defaultValue: Readonly<ICohortProject> = {};
