import dayjs from 'dayjs';
import { ICohortPollOption } from 'app/shared/model/cohort-poll-option.model';
import { ICohortPollResponse } from 'app/shared/model/cohort-poll-response.model';
import { IUser } from 'app/shared/model/user.model';
import { ICohort } from 'app/shared/model/cohort.model';
import { ICohortProject } from 'app/shared/model/cohort-project.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface ICohortPoll {
  id?: number;
  question?: string | null;
  active?: boolean | null;
  lastActiveTime?: string | null;
  state?: EntityState | null;
  voteCount?: number | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  options?: ICohortPollOption[] | null;
  responses?: ICohortPollResponse[] | null;
  user?: IUser | null;
  cohort?: ICohort | null;
  project?: ICohortProject | null;
}

export const defaultValue: Readonly<ICohortPoll> = {
  active: false,
};
