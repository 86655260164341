import dayjs from 'dayjs';
import { IRoomChat } from 'app/shared/model/room-chat.model';

export interface IChatContent {
  id?: number;
  content?: string | null;
  meta?: string | null;
  type?: string | null;
  likedCount?: number | null;
  replyCount?: number | null;
  createdAt?: string | null;
  roomChat?: IRoomChat | null;
}

export const defaultValue: Readonly<IChatContent> = {};
