import { ICohortPoll } from 'app/shared/model/cohort-poll.model';

export interface ICohortPollOption {
  id?: number;
  title?: string;
  count?: number | null;
  poll?: ICohortPoll | null;
}

export const defaultValue: Readonly<ICohortPollOption> = {};
