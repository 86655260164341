import dayjs from 'dayjs';
import { ICohort } from 'app/shared/model/cohort.model';

export interface ICohortCategory {
  id?: number;
  name?: string;
  type?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  cohorts?: ICohort[] | null;
}

export const defaultValue: Readonly<ICohortCategory> = {};
