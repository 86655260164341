import dayjs from 'dayjs';
import { IChatPollResponse } from 'app/shared/model/chat-poll-response.model';
import { IChatPollOption } from 'app/shared/model/chat-poll-option.model';
import { IRoomChat } from 'app/shared/model/room-chat.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface IChatPoll {
  id?: number;
  question?: string | null;
  active?: boolean | null;
  timeStamp?: string | null;
  userId?: string | null;
  lastActiveTime?: string | null;
  state?: EntityState | null;
  voteCount?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  responses?: IChatPollResponse[] | null;
  options?: IChatPollOption[] | null;
  chat?: IRoomChat | null;
}

export const defaultValue: Readonly<IChatPoll> = {
  active: false,
};
