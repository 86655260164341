import dayjs from 'dayjs';
import { IFile } from 'app/shared/model/file.model';
import { IFileGroup } from 'app/shared/model/file-group.model';
import { AttachmentType } from 'app/shared/model/enumerations/attachment-type.model';

export interface IFileAttachment {
  id?: number;
  type?: AttachmentType | null;
  fileName?: string | null;
  fileUrl?: string | null;
  fileType?: string | null;
  valid?: boolean | null;
  belongsToUrn?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  file?: IFile | null;
  group?: IFileGroup | null;
}

export const defaultValue: Readonly<IFileAttachment> = {
  valid: false,
};
