import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IRoom } from 'app/shared/model/room.model';

export interface IRoomReaction {
  id?: number;
  count?: number | null;
  category?: string | null;
  timeStamp?: string | null;
  timeFromStart?: number | null;
  createdAt?: string | null;
  user?: IUser | null;
  room?: IRoom | null;
}

export const defaultValue: Readonly<IRoomReaction> = {};
