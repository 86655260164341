import dayjs from 'dayjs';
import { ICohortProject } from 'app/shared/model/cohort-project.model';
import { ICohortPost } from 'app/shared/model/cohort-post.model';
import { ICohortPoll } from 'app/shared/model/cohort-poll.model';
import { IRoom } from 'app/shared/model/room.model';
import { ITag } from 'app/shared/model/tag.model';
import { ICohortCategory } from 'app/shared/model/cohort-category.model';
import { IUser } from 'app/shared/model/user.model';
import { CohortVisibility } from 'app/shared/model/enumerations/cohort-visibility.model';
import { CohortMembership } from 'app/shared/model/enumerations/cohort-membership.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface ICohort {
  id?: number;
  name?: string;
  bio?: string | null;
  type?: string;
  visibility?: CohortVisibility | null;
  membershipType?: CohortMembership | null;
  category?: string;
  coverImageUrl?: string | null;
  state?: EntityState | null;
  oneLinerStatus?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  projects?: ICohortProject[] | null;
  posts?: ICohortPost[] | null;
  polls?: ICohortPoll[] | null;
  rooms?: IRoom[] | null;
  tags?: ITag[] | null;
  categories?: ICohortCategory[] | null;
  users?: IUser[] | null;
}

export const defaultValue: Readonly<ICohort> = {};
