import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import cohort from 'app/entities/cohort/cohort.reducer';
// prettier-ignore
import role from 'app/entities/role/role.reducer';
// prettier-ignore
import permission from 'app/entities/permission/permission.reducer';
// prettier-ignore
import userGroup from 'app/entities/user-group/user-group.reducer';
// prettier-ignore
import tag from 'app/entities/tag/tag.reducer';
// prettier-ignore
import file from 'app/entities/file/file.reducer';
// prettier-ignore
import fileGroup from 'app/entities/file-group/file-group.reducer';
// prettier-ignore
import searchHistory from 'app/entities/search-history/search-history.reducer';
// prettier-ignore
import accessHistory from 'app/entities/access-history/access-history.reducer';
// prettier-ignore
import userSettings from 'app/entities/user-settings/user-settings.reducer';
// prettier-ignore
import userInterest from 'app/entities/user-interest/user-interest.reducer';
// prettier-ignore
import school from 'app/entities/school/school.reducer';
// prettier-ignore
import work from 'app/entities/work/work.reducer';
// prettier-ignore
import userTag from 'app/entities/user-tag/user-tag.reducer';
// prettier-ignore
import enterprise from 'app/entities/enterprise/enterprise.reducer';
// prettier-ignore
import serviceSubscription from 'app/entities/service-subscription/service-subscription.reducer';
// prettier-ignore
import report from 'app/entities/report/report.reducer';
// prettier-ignore
import cohortProject from 'app/entities/cohort-project/cohort-project.reducer';
// prettier-ignore
import cohortTeam from 'app/entities/cohort-team/cohort-team.reducer';
// prettier-ignore
import cohortCategory from 'app/entities/cohort-category/cohort-category.reducer';
// prettier-ignore
import cohortPost from 'app/entities/cohort-post/cohort-post.reducer';
// prettier-ignore
import cohortPostComment from 'app/entities/cohort-post-comment/cohort-post-comment.reducer';
// prettier-ignore
import cohortPoll from 'app/entities/cohort-poll/cohort-poll.reducer';
// prettier-ignore
import cohortPollOption from 'app/entities/cohort-poll-option/cohort-poll-option.reducer';
// prettier-ignore
import cohortPollResponse from 'app/entities/cohort-poll-response/cohort-poll-response.reducer';
// prettier-ignore
import cohortDeliverable from 'app/entities/cohort-deliverable/cohort-deliverable.reducer';
// prettier-ignore
import fileAttachment from 'app/entities/file-attachment/file-attachment.reducer';
// prettier-ignore
import room from 'app/entities/room/room.reducer';
// prettier-ignore
import roomTag from 'app/entities/room-tag/room-tag.reducer';
// prettier-ignore
import roomSpeaker from 'app/entities/room-speaker/room-speaker.reducer';
// prettier-ignore
import roomReaction from 'app/entities/room-reaction/room-reaction.reducer';
// prettier-ignore
import roomRating from 'app/entities/room-rating/room-rating.reducer';
// prettier-ignore
import roomLiked from 'app/entities/room-liked/room-liked.reducer';
// prettier-ignore
import roomView from 'app/entities/room-view/room-view.reducer';
// prettier-ignore
import roomInterest from 'app/entities/room-interest/room-interest.reducer';
// prettier-ignore
import roomNote from 'app/entities/room-note/room-note.reducer';
// prettier-ignore
import roomFeedback from 'app/entities/room-feedback/room-feedback.reducer';
// prettier-ignore
import roomChat from 'app/entities/room-chat/room-chat.reducer';
// prettier-ignore
import chatContent from 'app/entities/chat-content/chat-content.reducer';
// prettier-ignore
import chatPoll from 'app/entities/chat-poll/chat-poll.reducer';
// prettier-ignore
import chatPollOption from 'app/entities/chat-poll-option/chat-poll-option.reducer';
// prettier-ignore
import chatPollResponse from 'app/entities/chat-poll-response/chat-poll-response.reducer';
// prettier-ignore
import notification from 'app/entities/notification/notification.reducer';
// prettier-ignore
import invoice from 'app/entities/invoice/invoice.reducer';
// prettier-ignore
import form from 'app/entities/form/form.reducer';
// prettier-ignore
import formData from 'app/entities/form-data/form-data.reducer';
// prettier-ignore
import formFieldGroup from 'app/entities/form-field-group/form-field-group.reducer';
// prettier-ignore
import formField from 'app/entities/form-field/form-field.reducer';
// prettier-ignore
import cohortDeliverableSubmission from 'app/entities/cohort-deliverable-submission/cohort-deliverable-submission.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  cohort,
  role,
  permission,
  userGroup,
  tag,
  file,
  fileGroup,
  searchHistory,
  accessHistory,
  userSettings,
  userInterest,
  school,
  work,
  userTag,
  enterprise,
  serviceSubscription,
  report,
  cohortProject,
  cohortTeam,
  cohortCategory,
  cohortPost,
  cohortPostComment,
  cohortPoll,
  cohortPollOption,
  cohortPollResponse,
  cohortDeliverable,
  fileAttachment,
  room,
  roomTag,
  roomSpeaker,
  roomReaction,
  roomRating,
  roomLiked,
  roomView,
  roomInterest,
  roomNote,
  roomFeedback,
  roomChat,
  chatContent,
  chatPoll,
  chatPollOption,
  chatPollResponse,
  notification,
  invoice,
  form,
  formData,
  formFieldGroup,
  formField,
  cohortDeliverableSubmission,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
};

export default rootReducer;
