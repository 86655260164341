// import './app.scss';
import 'app/config/dayjs.ts';

import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter as Router, Switch, Redirect} from "react-router-dom";


import "./style.less";

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Translate } from "react-jhipster";
import PrivateRoute from "@/shared/auth/private-route";
import ErrorBoundaryRoute from "@/shared/error/error-boundary-route";
import Login from "@/modules/login";
import Logout from "@/modules/login/logout";
import Register from "@/modules/account/register/register";
import Activate from "@/modules/account/activate/activate";
import PasswordResetInit from "@/modules/account/password-reset/init/password-reset-init";
import PasswordResetFinish from "@/modules/account/password-reset/finish/password-reset-finish";

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/shared/layout/dashboard'),
  loading: () => <div>loading ...</div>,
});


export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const renderDevRibbon = () =>
    isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  if (isAuthenticated && !isAdmin) {
    return <Redirect to='https://dotslive.com' />
  }

  return (
    <Router>
      {renderDevRibbon()}
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/account/register" component={Register} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <Redirect to='/login' />
      </Switch>
    </Router>
  )
};

export default App;

/**
 *
 *       <div className="app-container">
 *         <ErrorBoundary>
 *           <Header
 *             isAuthenticated={isAuthenticated}
 *             isAdmin={isAdmin}
 *             currentLocale={currentLocale}
 *             ribbonEnv={ribbonEnv}
 *             isInProduction={isInProduction}
 *             isOpenAPIEnabled={isOpenAPIEnabled}
 *           />
 *         </ErrorBoundary>
 *         <div className="container-fluid view-container" id="app-view-container">
 *           <Card className="jh-card">
 *             <ErrorBoundary>
 *             </ErrorBoundary>
 *           </Card>
 *           <Footer />
 *         </div>
 *       </div>
 */
