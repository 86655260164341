import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ICohortPost } from 'app/shared/model/cohort-post.model';

export interface ICohortPostComment {
  id?: number;
  content?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  user?: IUser | null;
  post?: ICohortPost | null;
}

export const defaultValue: Readonly<ICohortPostComment> = {};
