import dayjs from 'dayjs';
import { IFormFieldGroup } from 'app/shared/model/form-field-group.model';

export interface IFormField {
  id?: number;
  name?: string;
  key?: string;
  placeholder?: string;
  type?: string;
  nullable?: boolean | null;
  options?: string | null;
  rule?: string | null;
  meta?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  groups?: IFormFieldGroup[] | null;
}

export const defaultValue: Readonly<IFormField> = {
  nullable: false,
};
