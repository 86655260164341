import dayjs from 'dayjs';
import { ISearchHistory } from 'app/shared/model/search-history.model';

export interface IAccessHistory {
  id?: number;
  url?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  search?: ISearchHistory | null;
}

export const defaultValue: Readonly<IAccessHistory> = {};
