import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ITag } from 'app/shared/model/tag.model';
import { IFile } from 'app/shared/model/file.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface IFileGroup {
  id?: number;
  name?: string | null;
  slug?: string | null;
  type?: string | null;
  state?: EntityState | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  users?: IUser[] | null;
  groups?: IFileGroup[] | null;
  tags?: ITag[] | null;
  subGroups?: IFileGroup[] | null;
  files?: IFile[] | null;
}

export const defaultValue: Readonly<IFileGroup> = {};
