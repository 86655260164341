import dayjs from 'dayjs';
import { IFormField } from 'app/shared/model/form-field.model';
import { IForm } from 'app/shared/model/form.model';

export interface IFormFieldGroup {
  id?: number;
  title?: string;
  key?: string;
  desc?: string | null;
  type?: string;
  rule?: string | null;
  meta?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  fields?: IFormField[] | null;
  forms?: IForm[] | null;
}

export const defaultValue: Readonly<IFormFieldGroup> = {};
