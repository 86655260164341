import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IPermission } from 'app/shared/model/permission.model';
import { IRoom } from 'app/shared/model/room.model';

export interface IRole {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  users?: IUser[] | null;
  permissions?: IPermission[] | null;
  rooms?: IRoom[] | null;
}

export const defaultValue: Readonly<IRole> = {};
