import dayjs from 'dayjs';
import { IFile } from 'app/shared/model/file.model';
import { IFileGroup } from 'app/shared/model/file-group.model';
import { ICohort } from 'app/shared/model/cohort.model';

export interface ITag {
  id?: number;
  name?: string | null;
  type?: string | null;
  lang?: string | null;
  createdAt?: string | null;
  files?: IFile[] | null;
  fileGroups?: IFileGroup[] | null;
  cohorts?: ICohort[] | null;
}

export const defaultValue: Readonly<ITag> = {};
