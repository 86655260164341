import dayjs from 'dayjs';
import { IInvoice } from 'app/shared/model/invoice.model';
import { SubscriptionState } from 'app/shared/model/enumerations/subscription-state.model';

export interface IServiceSubscription {
  id?: number;
  userId?: string | null;
  type?: string | null;
  status?: SubscriptionState | null;
  endDate?: string | null;
  nextBill?: string | null;
  failedNote?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  invoice?: IInvoice | null;
}

export const defaultValue: Readonly<IServiceSubscription> = {};
