import dayjs from 'dayjs';
import { IRole } from 'app/shared/model/role.model';

export interface IPermission {
  id?: number;
  node?: string | null;
  description?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  roles?: IRole[] | null;
}

export const defaultValue: Readonly<IPermission> = {};
