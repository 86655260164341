import dayjs from 'dayjs';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface IRoomFeedback {
  id?: number;
  comment?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  state?: EntityState | null;
}

export const defaultValue: Readonly<IRoomFeedback> = {};
