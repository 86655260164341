import React from 'react';

import "./style.less";

import {translate} from 'react-jhipster';
import {DefaultFooter} from "@ant-design/pro-layout";
import {GithubOutlined} from "@ant-design/icons";

const Footer = () => {
  const defaultMessage = translate('global.copyright');
  const currentYear = new Date().getFullYear();

  return (
    <DefaultFooter
      copyright={`${currentYear} ${defaultMessage}`}
      links={[
        {
          key: 'DotsLive',
          title: 'DotsLive',
          href: 'https://dotslive.com',
          blankTarget: true,
        },
        {
          key: 'github',
          title: <GithubOutlined />,
          href: 'https://github.com/ant-design/ant-design-pro',
          blankTarget: true,
        },
        {
          key: 'Ant Design',
          title: 'Ant Design',
          href: 'https://ant.design',
          blankTarget: true,
        },
      ]}
    />
  );
}

export default Footer;
