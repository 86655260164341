import React from 'react';
import { locales, languages } from 'app/config/translation';
import { Select } from 'antd';

const { Option } = Select;

export const LocaleMenu = ({ currentLocale, onChange }: { currentLocale: string; onChange: (value: any) => void }) =>
  Object.keys(languages).length > 1 ? (
    <Select value={currentLocale} style={{ width: 100 }} onChange={onChange}>
      {locales.map(locale => (
        <Option key={locale} value={locale}>
          {languages[locale].name}
        </Option>
      ))}
    </Select>
  ) : null;
