import dayjs from 'dayjs';
import { ICohortTeam } from 'app/shared/model/cohort-team.model';
import { ICohortDeliverableSubmission } from 'app/shared/model/cohort-deliverable-submission.model';
import { ICohortProject } from 'app/shared/model/cohort-project.model';

export interface ICohortDeliverable {
  id?: number;
  name?: string;
  description?: string | null;
  dueDate?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  teams?: ICohortTeam[] | null;
  submissions?: ICohortDeliverableSubmission[] | null;
  project?: ICohortProject | null;
}

export const defaultValue: Readonly<ICohortDeliverable> = {};
