import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IFileGroup } from 'app/shared/model/file-group.model';
import { ITag } from 'app/shared/model/tag.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface IFile {
  id?: number;
  name?: string | null;
  originalName?: string | null;
  fileType?: string | null;
  hash?: string | null;
  type?: string | null;
  state?: EntityState | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  users?: IUser[] | null;
  groups?: IFileGroup[] | null;
  tags?: ITag[] | null;
}

export const defaultValue: Readonly<IFile> = {};
