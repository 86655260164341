import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { InvoiceState } from 'app/shared/model/enumerations/invoice-state.model';

export interface IInvoice {
  id?: number;
  outId?: string;
  code?: string | null;
  details?: string | null;
  totalAmount?: number | null;
  receivedAmount?: number | null;
  paymentMethod?: string | null;
  state?: InvoiceState | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IInvoice> = {};
