import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface ISchool {
  id?: number;
  name?: string;
  type?: string;
  userId?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  users?: IUser[] | null;
}

export const defaultValue: Readonly<ISchool> = {};
